<template>
    <h1>AVG Filter</h1>
    <br>
    <CContainer>
        <CRow>
            <CCol :md="3">
                <input type="date" class="form-control" v-model="avgDate" />
            </CCol>
        </CRow>
        <CRow><br></CRow>
        <CCard>
            <CCardBody>
                <CRow>
                    <CCol :md="12">
                        <CRow style="border-bottom: 4px black solid;padding: 5px;">
                            <CCol :md="1">
                                Eventid
                            </CCol>
                            <CCol :md="4">
                                Match
                            </CCol>
                            <CCol :md="3">
                                Lega
                            </CCol>
                            <CCol :md="1">
                                Ora
                            </CCol>
                            <CCol :md="2">
                                Azione
                            </CCol>
                        </CRow>
                        <CRow v-for="(game, indG) in avgGames" :key="indG" style="border-bottom: 1px black solid;padding: 5px; background-color: rgba(164, 171, 255, .1)">
                            <CRow>
                                <CCol :md="1">
                                    {{ game.eventid }}
                                </CCol>
                                <CCol :md="4">
                                    {{ game.eventview.home.name + " vs " + game.eventview.away.name }}
                                </CCol>
                                <CCol :md="3">
                                    {{ game.eventview.league.name }}
                                </CCol>
                                <CCol :md="1">
                                    {{ timeConverter(game.eventview.time)[0] }}
                                </CCol>
                                <CCol :md="2">
                                    <CButton href="#" @click="clickHandle(game)" color="danger" shape="rounded-pill">
                                        Elimina
                                    </CButton>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol style="border: 1px solid green;">
                                    Home Goals: {{ game.homegoals }}<br>Away Goals: {{ game.awaygoals }}
                                </CCol>
                                <CCol style="border: 1px solid green;">
                                    AVG: {{ Math.floor(parseFloat(game.avg) * 100 ) / 100 }}
                                </CCol>

                            </CRow>

                        </CRow>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>

    </CContainer>
</template>

<script>
import { ref, watch } from 'vue-demi';
import { useAVGStore } from '../../store/avg_store';
import { timeConverter } from '../../plugins/tools';
export default {
    name:'AVGfilter',
    setup() {
        const avgDate = ref(new Date());
        const store = useAVGStore();
        const avgGames = ref([]);
        const removedGame = ref(false);

        watch(avgDate, (val) => {
            const dateToValidate = val.split('-');
            const dateToSend = dateToValidate[0]+'-'+dateToValidate[1]+'-'+dateToValidate[2];
            store.setDate(dateToSend);
            store.getGames.then(data => {
                avgGames.value = data.data;
            });
        })

        watch(removedGame, (val) => {
            if (val) {
                store.getGames.then(data => {
                    avgGames.value = data.data;
                });
                removedGame.value = false;
            }
        })

        const clickHandle = (game) => {
            store.removeGame(game.eventid).then(response => {
                if (response.data == 200) {
                    const index = avgGames.value.indexOf(game);
                    if (index != -1) {
                        avgGames.value.splice(index, 1);
                    }
                }
            });
        }

        return {
            avgDate,
            avgGames,
            clickHandle,
            timeConverter
        }
    }
}
</script>

<style>
</style>
